import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const Graph = ({ graphData }) => {
	const graphOptions = {
		getExtremesFromAll: true,
		chart: {
			type: "line",
			zoomType: "x",
			ignoreHiddenSeries: false
		},
		title: {
			text: ""
		},
		xAxis: {
			type: "datetime",
			dateTimeLabelFormats: {
				millisecond: "%H:%M:%S.%L",
				second: "%H:%M:%S",
				minute: "%H:%M",
				hour: "%H:%M",
				day: "%e. %b",
				week: "%e. %b",
				month: "%b '%y",
				year: "%Y"
			}
			// labels: {
			//     format: '{value:%Y-%b-%e}'
			//   },
		},
		yAxis: [
			{
				title: {
					text: graphData.symbol1 //symbol1
				},
				startOnTick: false,
				endOnTick: false
			},
			{
				title: {
					text: graphData.symbol3 //symbol3
				},
				opposite: true,
				startOnTick: false,
				endOnTick: false
			}
		],
		tooltip: {
			shared: true,
			valueDecimals: 3
		},
		series: []
	};

	const series = []

	graphData?.length && graphData.forEach(data => {
		series.push({
			type: "line",
			name: data.name, //name
			data: data.data.sort((a, b) => a[0] - b[0]), //data
			yAxis: 0,
			tooltip: {
				valueSuffix: data.symbol //symbol
			},
			_colorIndex: 0,
			_symbolIndex: 0,
			connectNulls: true

		})
	});

	graphOptions.series = series

	return (
		<div>
			{(graphData.length > 0) && (
				<HighchartsReact
					highcharts={Highcharts}
					options={graphOptions}
				/>
			)}
		</div>
	);
};
