import "./App.css";
import { NavBar } from "./Components/NavBar";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
	return (
		<div className="App">
			{/* <Sidebar /> */}
			{/* <div className="main-section"> */}
			<div>
				<NavBar />
				<Outlet />
			</div>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={true}
				autoClose={1000}
				theme="dark"
			/>
		</div>
	);
}

export default App;
