import React, { useState } from "react";
import { callApi } from "../actions/apiMethods";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { token } from "../localStorage";

export const Signup = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const signup = async (e) => {
		e.preventDefault();
		if (formEntries.user_password !== formEntries.confirm_user_password) {
			toast.error("Passwords do not match");
			return;
		}
		if (!formEntries.username || !formEntries.user_password || !formEntries.confirm_user_password) {
			toast.error("Missing fields");
			return;
		}
		setLoading(true);
		const res = await callApi("POST", "auth/signup", formEntries);
		setLoading(false);
		if (!res) return;
		if (res.token) {
			toast.success("User created successfully");
			token.set(res.token);
			navigate("/");
		}
	};

	const [formEntries, setFormEntries] = useState({
		username: "",
		user_password: "",
		confirm_user_password: ""
	});

	const setNewForm = (e) => {
		const { name, value } = e.target;
		setFormEntries((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={true}
				autoClose={5000}
				theme="dark"
			/>
			<div className="form-container">
				<h1>MMS Aggregator</h1>
				<h1>Signup</h1>
				<form
					className="Form login-form"
					autoComplete="off"
				>
					<label>Username</label>
					<input
						type="text"
						name="username"
						value={formEntries.username}
						onChange={setNewForm}
						autoComplete="off"
					/>

					<label>Password</label>
					<input
						type="password"
						name="user_password"
						value={formEntries.user_password}
						onChange={setNewForm}
						autoComplete="new-password"
					/>

					<label>Confirm Password</label>
					<input
						type="password"
						name="confirm_user_password"
						value={formEntries.confirm_user_password}
						onChange={setNewForm}
						autoComplete="off"
					/>

					<br />
					<LoadingButton
						type="submit"
						loading={loading}
						variant="contained"
						onClick={(e) => signup(e)}
					>
						Signup
					</LoadingButton>
				</form>
				<div
					className="flex"
					style={{ alignItems: "center" }}
				>
					<p>Have an account?</p>
					<Link to={"/login"}>
						{" "}
						<button> Login </button>{" "}
					</Link>
				</div>
			</div>
		</>
	);
};
