import React, { useState } from "react";
import { callApi } from "../actions/apiMethods";
import { token, setToLocalStorage } from "../localStorage";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export const Login = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const login = async (e) => {
		e.preventDefault();
		if (!formEntries.username || !formEntries.user_password) {
			toast.error("Missing fields");
			return;
		}
		setLoading(true);
		const res = await callApi("POST", "auth/login", formEntries);
		setLoading(false);
		if (!res) return;
		if (res.token) {
			if (res.otherTokens.length > 0) {
				res.otherTokens.forEach((element) => {
					setToLocalStorage(element.domain, element.token);
				});
				setToLocalStorage("all_tokens", JSON.stringify(res.otherTokens));
			}
			token.set(res.token);
			navigate("/");
		}
	};

	const [formEntries, setFormEntries] = useState({
		username: "",
		user_password: ""
	});

	const setNewForm = (e) => {
		const { name, value } = e.target;
		setFormEntries((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={true}
				autoClose={5000}
				theme="dark"
			/>
			<div className="form-container">
				<h1>MMS Aggregator</h1>
				<h1>Login</h1>
				<form className="Form login-form">
					<label>Username</label>
					<input
						type="text"
						name="username"
						value={formEntries.username}
						onChange={setNewForm}
					/>

					<label>Password</label>
					<input
						type="password"
						name="user_password"
						value={formEntries.user_password}
						onChange={setNewForm}
					/>

					<br />
					<LoadingButton
						type="submit"
						loading={loading}
						variant="contained"
						onClick={(e) => login(e)}
					>
						Login
					</LoadingButton>
				</form>
				<div
					className="flex"
					style={{ alignItems: "center" }}
				>
					<p>Need an account?</p>
					<Link to={"/signup"}>
						<button>Signup </button>
					</Link>
				</div>
			</div>
		</>
	);
};
