import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";

export class mmsUserStore {
	items = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	fetchItems = async () => {
		this.loading = true;
		const response = await callApi("GET", "mmsUsers");
		if (!response) return;

		runInAction(() => {
			this.items = response.message;
			this.loading = false;
		});
	};
}
