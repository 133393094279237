const AUTH_KEY = 'auth_key';

export const setToLocalStorage = (key, value) => localStorage.setItem(key, value);

export const getFromLocalStorage = (key) => localStorage.getItem(key);

export const removeFromLocalStorage = (key) => localStorage.removeItem(key)

export const token = {
    get: () => getFromLocalStorage(AUTH_KEY),
    set: (token) => setToLocalStorage(AUTH_KEY, token),
    remove: () => removeFromLocalStorage(AUTH_KEY)
}
