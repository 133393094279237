import React from "react";
import jwt_decode from "jwt-decode";
import { token } from "../localStorage";
import { observer } from "mobx-react-lite";
import { ServerList } from "./Server/ServerList";

export const Home = observer(() => {
	const user = jwt_decode(token.get()).user;
	return <div>
		<p style={{ textAlign: "center" }}>Welcome, {user}</p>
		<ServerList />
	</div>;
});
