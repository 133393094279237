import { makeAutoObservable, runInAction } from "mobx";
import { getAll } from "../actions/apiMethods";

export class stationStore {
	items = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	fetchItems = async () => {
		this.loading = true;
		const response = await getAll("station");
		if (!response) return;
		runInAction(() => {
			this.items = response.message;
			this.loading = false;
		});
	};
}
