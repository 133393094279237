import React from "react";
import { redirect, createHashRouter } from "react-router-dom";
import { checkRedirectToLogin } from "./helpers/isLoggedIn";
import { isLoggedIn } from "./helpers/isLoggedIn";
import { Login } from "./Components/Login";
import App from "./App";
import { ForecastAnalysis } from "./Components/ForecastAnalysis";
import "./App.css";
import { Home } from "./Components/Home";
import { Signup } from "./Components/Signup";
import { EditUserServer } from "./Components/Server/EditUserServer";
import { EditServer } from "./Components/Server/EditServer";
import { GeoServerComparision } from "./Components/GeoServerComparision";

export const router = createHashRouter([
	{
		element: <App />,
		loader: checkRedirectToLogin,
		children: [
			{
				path: "/",
				element: <Home />,
				children: [
					{
						path: "/user-server/:id",
						element: <EditUserServer />
					},
					{
						path: "/server/:id",
						element: <EditServer />
					}
				]
			},
			{
				path: "/forecastanalysis",
				element: <ForecastAnalysis />
			},
			{
				path: "/geoservercomparision",
				element: <GeoServerComparision />
			},
		]
	},
	{
		path: "/login",
		element: <Login />,
		loader: () => {
			if (isLoggedIn()) return redirect("/");
			return null;
		}
	},
	{
		path: "/signup",
		element: <Signup />,
		loader: () => {
			if (isLoggedIn()) return redirect("/");
			return null;
		}
	}
]);
