import { serverStore } from "./server_store";
import { mmsUserStore } from "./mms_user_store";
import { stationStore } from "./station_store";

export class RootStore {
	constructor() {
		this.serverStore = new serverStore();
		this.mmsUserStore = new mmsUserStore();
		this.stationStore = new stationStore();
	}
}
