import jwt_decode from "jwt-decode";
import { token } from "../localStorage";
import { redirect } from "react-router-dom";

export const isLoggedIn = () => {
  const localToken = token.get();
  if (!localToken) return false;
  if (jwt_decode(localToken).exp > Date.now() / 1000) {
    return true;
  } else {
    token.remove();
    return false;
  }
};

export const checkRedirectToLogin = () => {
  if (!isLoggedIn()) return redirect("/login");
  return true;
};
