export const calculateCorrelationCoefficient = (data1, data2) => {
  if (data1.length !== data2.length) {
    return null;
  }

  const n = data1.length;
  let sum1 = 0;
  let sum2 = 0;
  let sum1Squared = 0;
  let sum2Squared = 0;
  let sumProducts = 0;

  for (let i = 0; i < n; i++) {
    sum1 += data1[i];
    sum2 += data2[i];
    sum1Squared += data1[i] ** 2;
    sum2Squared += data2[i] ** 2;
    sumProducts += data1[i] * data2[i];
  }

  const numerator = n * sumProducts - sum1 * sum2;
  const denominator = Math.sqrt((n * sum1Squared - sum1 ** 2) * (n * sum2Squared - sum2 ** 2));

  const r = numerator / denominator;
  return r;
};


// (sum of (forecast - observation)) / n
export const calculateMeanBiasError = (data1, data2) => {
  if (data1.length !== data2.length) {
    return null;
  }
  //data2 - forecast, data1 - observation
  let sum = 0;
  for (let i = 0; i < data1.length; i++) {
    sum += data2[i] - data1[i];
  }
  return sum / data1.length;
};


//data1 - observation, data2 - forecast
export const calculateMeanAbsolutePercentageError = (data1, data2) => {
  if (data1.length !== data2.length) {
    return null;
  }
  let sum = 0;
  for (let i = 0; i < data1.length; i++) {
    sum += Math.abs(data1[i] - data2[i]) / data1[i];
  }
  return (sum / data1.length) * 100;
};


export const calculateRMSE = (data1, data2) => {
  if (data1.length !== data2.length) {
    return null;
  }
  let sum = 0;
  for (let i = 0; i < data1.length; i++) {
    sum += (data1[i] - data2[i]) ** 2;
  }
  return Math.sqrt(sum / data1.length);
}