import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const NavBar = () => {
	const [value, setValue] = useState(0);
	const navigate = useNavigate();

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		const path = window.location.href;
		if (path.includes("/forecastanalysis")) setValue(1);
		else if (path.includes("/geoservercomparision")) setValue(2);
	}, []);

	return (
		<Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
			<Tabs
				value={value}
				onChange={handleChange}
				centered>
				<Tab
					component={Link}
					label="Home"
					to="/"
				/>
				<Tab
					component={Link}
					label="Forecast"
					to="/forecastanalysis"
				/>
				<Tab
					component={Link}
					label="GeoServer"
					to="/geoservercomparision"
				/>
			</Tabs>

			<button
				onClick={() => {
					localStorage.clear();
					navigate("/login");
					window.location.reload();
				}}>
				Logout
			</button>
		</Box>
	);
};
