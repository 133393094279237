import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { DateInput } from "./Common/Form";
import { getFromLocalStorage } from "../localStorage";
import { Graph } from "./Common/Graph";
import _ from "lodash";
import SelectInput from "./Common/SelectInput";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { calculateCorrelationCoefficient, calculateMeanAbsolutePercentageError, calculateMeanBiasError, calculateRMSE } from "../helpers/calculations";
import { RootContext } from "..";
import Modal from "./Common/Modal";
import { Spinner } from "./Common/Spinner";

export const ForecastAnalysis = observer(() => {
	const [serverOne, setServerOne] = useState("");
	const [serverTwo, setServerTwo] = useState("");

	const rootStore = useContext(RootContext);
	const serverStore = rootStore.serverStore;
	const stationStore = rootStore.stationStore;

	const [stationOne, setStationOne] = useState("");
	const [stationTwo, setStationTwo] = useState("");

	const [dataSeriesOne, setDataSeriesOne] = useState("");
	const [dataSeriesTwo, setDataSeriesTwo] = useState("");

	const [dataSeriesOneOptions, setDataSeriesOneOptions] = useState([]);
	const [dataSeriesTwoOptions, setDataSeriesTwoOptions] = useState([]);

	const [allForecastData, setAllForecastData] = useState([]);

	const [timestampOptions, setTimestampOptions] = useState([]);
	const [selectedTimestamps, setSelectedTimestamps] = useState([]);

	const [forecastBiasOption, setForecastBiasOption] = useState(0);

	const [filters, setFilters] = useState({
		date_from: moment().utc().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
		date_to: moment().utc().format("YYYY-MM-DDTHH:mm:ss")
	});

	const [graphData, setGraphData] = useState([]);

	const [isOptionLoading, setIsOptionLoading] = useState({
		dataSeriesOne: false,
		dataSeriesTwo: false,
		dataSeriesNew: false
	})

	const [loading, setLoading] = useState(false);

	const setNewFilters = (filter, value) => setFilters((prev) => ({ ...prev, [filter]: value }));

	const [addedForecasts, setAddedForecasts] = useState([]);
	const [derivedValues, setDerivedValues] = useState([
		{
			RMSE: 0,
			correlationCoefficient: 0,
			meanBiasError: 0,
			meanAbsoulutePercentageError: 0
		}
	]);

	const [newForecastOptions, setNewForecastOptions] = useState({
		server: "",
		station: "",
		dataSeriesOptions: [],
		dataSeries: "",
		timestamp: ""
	});

	const [showForecastAdd, setShowForecastAdd] = useState(false);

	const onChangeAddedForcastTimeStamp = (timestamp, timestamp_index) => {
		const uniqDataNew = !timestamp
			? allForecastData && allForecastData[timestamp_index + 1]?.map(({ time, value }) => {
				return [moment(time).valueOf(), Number(value)];
			})
			: allForecastData && allForecastData[timestamp_index + 1] &&
			allForecastData[timestamp_index + 1]
				.filter((item) => moment.utc(item.timestamp).format("YYYY-MM-DDTHH:mm:ss") === moment.utc(timestamp).format("YYYY-MM-DDTHH:mm:ss"))
				.map(({ time, value }) => {
					return [moment(time).valueOf(), Number(value)];
				});

		const forecastUniqData = !timestamp
			? allForecastData && allForecastData[timestamp_index + 1]?.map(({ time, value }) => {
				return [moment(time).valueOf(), Number(value)];
			})
			: allForecastData && allForecastData[timestamp_index + 1] &&
			allForecastData[timestamp_index + 1]
				.filter((item) => moment.utc(item.timestamp).format("YYYY-MM-DDTHH:mm:ss") === moment.utc(timestamp).format("YYYY-MM-DDTHH:mm:ss"))
				.map(({ time, value }) => {
					return [moment(time).valueOf(), Number(value)];
				});

		const timestampsData1 = new Set(graphData[0].data.map((item) => item[0]));
		const timestampsData2 = new Set(forecastUniqData.map((item) => item[0]));

		const commonTimestamps = new Set([...timestampsData1].filter((timestamp) => timestampsData2.has(timestamp)));
		const forecastIndex = graphData.findIndex((item) => item.forecastIndex === timestamp_index);

		const filteredData1 = [];
		const filteredData2 = [];
		const resultArray = [];

		let interpolatedData = [];

		if (commonTimestamps.size === 0) {
			toast.info("No data for the same timee. Interpolating...");
			// find data1 for timestamps in forecastUniqData

			//interpolation
			for (const timestamp of timestampsData2) {
				// closest times in timestampsData1
				const closestSmallerTimestamp = Array.from(timestampsData1).filter((item) => item < timestamp).reduce((prev, curr) => Math.abs(curr - timestamp) < Math.abs(prev - timestamp) ? curr : prev, 0);
				const closestGreaterTimestamp = Array.from(timestampsData1).filter((item) => item > timestamp).reduce((prev, curr) => Math.abs(curr - timestamp) < Math.abs(prev - timestamp) ? curr : prev, 0);

				// y = y1 + (x - x1) * ((y2 - y1) / (x2 - x1))
				if (!closestSmallerTimestamp || !closestGreaterTimestamp) continue;
				const y1 = graphData[0].data.find((item) => item[0] === closestSmallerTimestamp)[1];
				const y2 = graphData[0].data.find((item) => item[0] === closestGreaterTimestamp)[1];
				const interpolatedValue = y1 + (timestamp - closestSmallerTimestamp) * ((y2 - y1) / (closestGreaterTimestamp - closestSmallerTimestamp));
				interpolatedData.push([timestamp, interpolatedValue]);
				filteredData1.push(interpolatedValue);
				const value2 = forecastUniqData && forecastUniqData.find((item) => item[0] === timestamp)[1];
				filteredData2.push(value2);
				const difference = value2 - interpolatedValue;
				resultArray.push([timestamp, difference]);
			}
		}
		else {
			for (const timestamp of commonTimestamps) {
				const valueData1 = graphData[0].data.find((item) => item[0] === timestamp)[1];
				const valueData2 = forecastUniqData && forecastUniqData.find((item) => item[0] === timestamp)[1];
				filteredData1.push(valueData1);
				filteredData2.push(valueData2);
				const difference = valueData2 - valueData1;
				resultArray.push([timestamp, difference]);
			}
		}

		setGraphData((prev) => {
			const newGraphData = [...prev];
			newGraphData[timestamp_index + 1 + timestamp_index] = {
				data: uniqDataNew,
				name: graphData[timestamp_index + 1 + timestamp_index].name,
				symbol: graphData[timestamp_index + 1 + timestamp_index].symbol
			}
			if (forecastIndex || forecastIndex === 0)
				newGraphData[forecastIndex] = {
					data: resultArray,
					name: `Forecast Bias ${timestamp_index}`,
					symbol: '-',
					forecastIndex: timestamp_index
				}
			return newGraphData;
		});
		const squaredErrors = resultArray.map(([_, difference]) => difference ** 2);
		const meanSquaredError = squaredErrors.reduce((acc, error) => acc + error, 0) / squaredErrors.length;
		const rootMeanSquareError = Math.sqrt(meanSquaredError);
		setDerivedValues((prev) => {
			const newDerivedValues = [...prev];
			newDerivedValues[timestamp_index] = {
				RMSE: rootMeanSquareError,
				correlationCoefficient: calculateCorrelationCoefficient(filteredData1, filteredData2),
				meanBiasError: calculateMeanBiasError(filteredData1, filteredData2),
				meanAbsoulutePercentageError: calculateMeanAbsolutePercentageError(filteredData1, filteredData2)
			}
			return newDerivedValues;
		}
		);
	}

	useEffect(() => {
		if (!graphData.length) return
		renderGraph()
	}, [addedForecasts.length])


	useEffect(() => {
		if (!serverStore.user_servers.length) serverStore.fetchUserServers();
		if (!stationStore.items.length) stationStore.fetchItems();
	}, []);

	const renderGraph = async () => {
		setLoading(true);
		try {
			const token1 = getFromLocalStorage(serverOne);
			if (!serverOne || !serverTwo || !dataSeriesOne || !dataSeriesTwo) {
				toast.error("Select all options");
				setLoading(false);
				return;
			}
			const toSetAllForecastData = [];
			const toSetAllDerivedData = [];
			const toSetGraphData = []
			const res1 = await fetch(
				`${serverOne}/api/observation/index?series_id=${dataSeriesOne}&date_from=${moment.utc(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}&date_to=${moment
					.utc(filters.date_to)
					.format("YYYY-MM-DDTHH:mm:ss")}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token1}`
					}
				}
			);
			const data1 = await res1.json();

			let groupedObservation = _.groupBy(data1, (item) => item.time);
			let uniqueObservations = [];
			Object.keys(groupedObservation).forEach((datekey) => {
				const maxTimestampObject = groupedObservation[datekey].reduce((maxObj, currentObj) => {
					return new Date(currentObj.timestamp) > new Date(maxObj.timestamp) ? currentObj : maxObj;
				});
				uniqueObservations.push(maxTimestampObject);
			});

			const seriesData1 =
				uniqueObservations.map(({ time, value }) => {
					return [moment(time).valueOf(), +value];
				}) || [];

			const name1 = `${stationStore.items.slice().find((item) => item.server === serverOne)?.stations?.slice().find((station) => +station.id === +stationOne).name} (${data1[0] ? data1[0].name : ""})`;
			const symbol1 = data1[0] ? data1[0].symbol : "";

			toSetGraphData.push({
				data: seriesData1,
				name: name1,
				symbol: symbol1
			})
			toSetAllForecastData.push(data1);

			const token2 = getFromLocalStorage(serverTwo);
			const res2 = await fetch(
				`${serverTwo}/api/observation/index?series_id=${dataSeriesTwo}&date_from=${moment.utc(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}&date_to=${moment
					.utc(filters.date_to)
					.format("YYYY-MM-DDTHH:mm:ss")}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token2}`
					}
				}
			);
			const data2 = await res2.json();

			const dataWithTimestamps = data2.map((item) => {
				return {
					...item,
					timestamp: moment.utc(item.timestamp).format("YYYY-MM-DD HH:mm:ss"),
				};
			})
			const allUniqueTimeStamps = []
			const uniqueTimeStamps = _.uniqWith(dataWithTimestamps, (a, b) => a.timestamp === b.timestamp).map((item) => {
				return {
					timestamp: item.timestamp
				};
			});
			allUniqueTimeStamps.push(uniqueTimeStamps);

			const seriesData2 = data2.map((data) => {
				return [moment(data.time).valueOf(), Number(data.value)];
			});
			toSetAllForecastData.push(data2);

			const name2 = `${stationStore.items.slice().find((item) => item.server === serverTwo)?.stations?.slice().find((station) => +station.id === +stationTwo).name} (${data2[0] ? data2[0].name : ""})`;
			const symbol2 = data2[0] ? data2[0].symbol : "";

			toSetGraphData.push({
				data: seriesData2,
				name: name2,
				symbol: symbol2
			})

			//rootMeanSquareError, correlation, meanBias and meanAbsoulutePercentageError
			const timestampsData1 = new Set(seriesData1.map((item) => item[0]));
			const timestampsData2 = new Set(seriesData2.map((item) => item[0]));

			const commonTimestamps = new Set([...timestampsData1].filter((timestamp) => timestampsData2.has(timestamp)));
			const filteredData1 = [];
			const filteredData2 = [];

			if (commonTimestamps.size === 0) {
				toast.info("No data for the same time.");
			}

			const resultArray = []
			for (const timestamp of commonTimestamps) {
				const valueData1 = seriesData1 && seriesData1.find((item) => item[0] === timestamp)[1];
				const valueData2 = seriesData2 && seriesData2.find((item) => item[0] === timestamp)[1];
				filteredData1.push(valueData1);
				filteredData2.push(valueData2);
				const difference = valueData2 - valueData1;
				resultArray.push([timestamp, difference]);
			}

			toSetAllDerivedData.push({
				RMSE: calculateRMSE(filteredData1, filteredData2),
				correlationCoefficient: calculateCorrelationCoefficient(filteredData1, filteredData2),
				meanBiasError: calculateMeanBiasError(filteredData1, filteredData2),
				meanAbsoulutePercentageError: calculateMeanAbsolutePercentageError(filteredData1, filteredData2)
			})

			toSetGraphData.push({
				data: resultArray,
				name: 'Forecast Bias 0',
				symbol: '-',
				forecastIndex: 0
			})

			for (const forecast of addedForecasts) {
				const token = getFromLocalStorage(forecast.server);
				const res = await fetch(
					`${forecast.server}/api/observation/index?series_id=${forecast.dataSeries}&date_from=${moment.utc(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}&date_to=${moment
						.utc(filters.date_to)
						.format("YYYY-MM-DDTHH:mm:ss")}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				);
				const data = await res.json();
				toSetAllForecastData.push(data);
				const seriesData = data.map((data) => {
					return [moment(data.time).valueOf(), Number(data.value)];
				});
				const name = `${stationStore.items.slice().find((item) => item.server === forecast.server)?.stations?.slice().find((station) => +station.id === +forecast.station).name} (${data[0] ? data[0].name : ""})`;
				const symbol = data[0] ? data[0].symbol : "";
				toSetGraphData.push({
					data: seriesData,
					name: name,
					symbol: symbol
				})

				const dataWithTimestamps = data.map((item) => {
					return {
						...item,
						timestamp: moment.utc(item.timestamp).format("YYYY-MM-DD HH:mm:ss"),
					};
				})
				const uniqueTimeStamps = _.uniqWith(dataWithTimestamps, (a, b) => a.timestamp === b.timestamp).map((item) => {
					return {
						timestamp: item.timestamp
					};
				});
				allUniqueTimeStamps.push(uniqueTimeStamps);

				const timestampsData1 = new Set(seriesData1.map((item) => item[0]));
				const timestampsData2 = new Set(seriesData.map((item) => item[0]));

				const commonTimestamps = new Set([...timestampsData1].filter((timestamp) => timestampsData2.has(timestamp)));
				const filteredData1 = [];
				const filteredData2 = [];

				if (commonTimestamps.size === 0) {
					toast.info("No data for the same time.");
				}

				const resultArray = []
				for (const timestamp of commonTimestamps) {
					const valueData1 = seriesData1 && seriesData1.find((item) => item[0] === timestamp)[1];
					const valueData2 = seriesData && seriesData.find((item) => item[0] === timestamp)[1];
					filteredData1.push(valueData1);
					filteredData2.push(valueData2);
					const difference = valueData2 - valueData1;
					resultArray.push([timestamp, difference]);
				}

				toSetAllDerivedData.push({
					RMSE: calculateRMSE(filteredData1, filteredData2),
					correlationCoefficient: calculateCorrelationCoefficient(filteredData1, filteredData2),
					meanBiasError: calculateMeanBiasError(filteredData1, filteredData2),
					meanAbsoulutePercentageError: calculateMeanAbsolutePercentageError(filteredData1, filteredData2)
				})

				toSetGraphData.push({
					data: resultArray,
					name: `Forecast Bias 1`,
					symbol: '-',
					forecastIndex: addedForecasts.indexOf(forecast) + 1
				})
			}

			setDerivedValues(toSetAllDerivedData);
			setGraphData(toSetGraphData);
			setAllForecastData(toSetAllForecastData);
			setTimestampOptions(allUniqueTimeStamps);
			setSelectedTimestamps(Array(allUniqueTimeStamps.length).fill(""));
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e)
			toast.error("Error fetching data");
		}
	};
	console.log('graphData', graphData)
	const localStorageKeys = Object.keys(localStorage);
	if (!stationStore.items.length) return <Spinner />;

	return (
		<div>
			{showForecastAdd &&
				<Modal
					title={"Add forecast"}
					closeModal={() => {
						setShowForecastAdd(false);
					}}
				>
					<div>
						<SelectInput
							label={"Server"}
							options={serverStore.user_servers.filter((server) => localStorageKeys.includes(server.domain_name))}
							fields={{ label: "domain_name", value: "domain_name" }}
							value={newForecastOptions.server}
							onChange={(e) => {
								setNewForecastOptions({ server: e.target.value, station: "", dataSeries: "", dataSeriesOptions: [], timestamp: "" });
							}}
						/>

						<SelectInput
							label={"Station"}
							options={stationStore.items.slice().find((item) => item.server === newForecastOptions.server)?.stations?.slice()}
							fields={{ label: "name", value: "id" }}
							value={newForecastOptions.station}
							onChange={(e) => {
								setNewForecastOptions((prev) => ({ ...prev, station: e.target.value, dataSeries: "", dataSeriesOptions: [] }));
								setIsOptionLoading((prev) => ({ ...prev, dataSeriesNew: true }));
								const token = getFromLocalStorage(newForecastOptions.server);
								fetch(`${newForecastOptions.server}/api/station/${+e.target.value}/data-series`, {
									method: "GET",
									headers: {
										Authorization: `Bearer ${token}`
									}
								})
									.then((res) => res.json())
									.then((data) => {
										setNewForecastOptions((prev) => ({ ...prev, dataSeriesOptions: data }));
										setIsOptionLoading((prev) => ({ ...prev, dataSeriesNew: false }));
									})
									.catch((e) => {
										console.error(e);
									});
							}}
						/>

						<SelectInput
							label={"Data Series"}
							options={newForecastOptions.dataSeriesOptions.length > 0 ? newForecastOptions.dataSeriesOptions : []}
							fields={{ label: "name", value: "id" }}
							value={newForecastOptions.dataSeries}
							isLoading={isOptionLoading.dataSeriesNew}
							onChange={(e) => {
								setNewForecastOptions((prev) => ({ ...prev, dataSeries: e.target.value }));
							}}
						/>
					</div>

					<button onClick={(e) => {
						if (!newForecastOptions.server || !newForecastOptions.station || !newForecastOptions.dataSeries) {
							toast.error("Select all options");
							return;
						}
						setNewForecastOptions({ server: "", station: "", dataSeries: "", dataSeriesOptions: [], timestamp: "" });
						setShowForecastAdd(false);
						setAddedForecasts((prev) => [...prev, newForecastOptions]);
						setDerivedValues((prev) => [...prev, []]);
					}}>
						ADD
					</button>
				</Modal>
			}
			<h1 style={{ textAlign: "center" }}>Forecast Analysis</h1>
			<div style={{ display: "flex", justifyContent: "space-evenly" }}>
				<div>
					<p>Observation</p>
					<SelectInput
						label={"Server One"}
						options={serverStore.user_servers.filter((server) => localStorageKeys.includes(server.domain_name))}
						fields={{ label: "domain_name", value: "domain_name" }}
						value={serverOne}
						onChange={(e) => {
							setServerOne(e.target.value);
							setStationOne("");
							setDataSeriesOne("");
						}}
					/>

					<SelectInput
						label={"Station One"}
						options={stationStore.items.slice().find((item) => item.server === serverOne)?.stations?.slice()}
						fields={{ label: "name", value: "id" }}
						value={stationOne}
						onChange={(e) => {
							setIsOptionLoading((prev) => ({ ...prev, dataSeriesOne: true }));
							setStationOne(e.target.value);
							const token = getFromLocalStorage(serverOne);
							fetch(`${serverOne}/api/station/${+e.target.value}/data-series`, {
								method: "GET",
								headers: {
									Authorization: `Bearer ${token}`
								}
							})
								.then((res) => res.json())
								.then((data) => {
									setDataSeriesOne("");
									setDataSeriesOneOptions(data);
									setIsOptionLoading((prev) => ({ ...prev, dataSeriesOne: false }));
								})
								.catch((e) => {
									console.error(e);
								});
						}}
					/>

					<SelectInput
						label={"Data Series One"}
						options={dataSeriesOneOptions.length > 0 ? dataSeriesOneOptions : []}
						fields={{ label: "name", value: "id" }}
						value={dataSeriesOne}
						isLoading={isOptionLoading.dataSeriesOne}
						onChange={(e) => {
							setDataSeriesOne(e.target.value);
						}}
					/>

					<DateInput
						label={"Date From"}
						type="datetime-local"
						value={moment(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}
						onChange={(e) => setNewFilters("date_from", e.target.value)}
					/>
					<DateInput
						label={"Date To"}
						type="datetime-local"
						value={moment(filters.date_to).format("YYYY-MM-DDTHH:mm:ss")}
						onChange={(e) => setNewFilters("date_to", e.target.value)}
					/>

					<LoadingButton
						loading={loading}
						variant="contained"
						onClick={() => renderGraph()}
					>
						View
					</LoadingButton>
				</div>

				<div>
					<p>Forecast</p>
					<SelectInput
						label={"Server Two"}
						options={serverStore.user_servers.filter((server) => localStorageKeys.includes(server.domain_name))}
						fields={{ label: "domain_name", value: "domain_name" }}
						value={serverTwo}
						onChange={(e) => {
							setIsOptionLoading((prev) => ({ ...prev, stationTwo: true }));
							setServerTwo(e.target.value);
							setStationTwo("");
							setDataSeriesTwo("");
						}}
					/>

					<SelectInput
						label={"Station Two"}
						options={stationStore.items.slice().find((item) => item.server === serverTwo)?.stations?.slice()}
						fields={{ label: "name", value: "id" }}
						value={stationTwo}
						onChange={(e) => {
							setStationTwo(e.target.value);
							setIsOptionLoading((prev) => ({ ...prev, dataSeriesTwo: true }));
							const token = getFromLocalStorage(serverTwo);
							fetch(`${serverTwo}/api/station/${+e.target.value}/data-series`, {
								method: "GET",
								headers: {
									Authorization: `Bearer ${token}`
								}
							})
								.then((res) => res.json())
								.then((data) => {
									setDataSeriesTwo("");
									setDataSeriesTwoOptions(data);
									setIsOptionLoading((prev) => ({ ...prev, dataSeriesTwo: false }));
								})
								.catch((e) => {
									console.error(e);
								});
						}}
					/>

					<SelectInput
						label={"Data Series Two"}
						options={dataSeriesTwoOptions.length > 0 ? dataSeriesTwoOptions : []}
						fields={{ label: "name", value: "id" }}
						value={dataSeriesTwo}
						isLoading={isOptionLoading.dataSeriesTwo}
						onChange={(e) => {
							setDataSeriesTwo(e.target.value);
						}}
					/>

					<SelectInput
						label={"Timestamp"}
						options={timestampOptions.length > 0 ? timestampOptions[0] : []}
						fields={{ label: "timestamp", value: "timestamp" }}
						value={selectedTimestamps[0]}
						onChange={(e) => {
							setSelectedTimestamps((prev) => {
								const newTimestamps = [...prev];
								newTimestamps[0] = e.target.value;
								return newTimestamps;
							});
							onChangeAddedForcastTimeStamp(e.target.value, 0);
						}}
					/>
					<div
						className="flex"
						style={{ justifyContent: "center" }}
					>
						<i
							className="fa fa-caret-left hovericon"
							style={{ fontSize: "32px" }}
							onClick={(e) => {
								const index = timestampOptions[0].findIndex((item) => item.timestamp === selectedTimestamps[0]);
								if (index > 0) {
									setSelectedTimestamps((prev) => {
										const newTimestamps = [...prev];
										newTimestamps[0] = timestampOptions[0][index - 1].timestamp;
										return newTimestamps;
									});
									onChangeAddedForcastTimeStamp(timestampOptions[0][index - 1].timestamp, 0);
								}
							}}
						/>

						<i
							className="fa fa-solid fa-caret-right hovericon"
							style={{ paddingLeft: "20px", fontSize: "32px" }}
							onClick={(e) => {
								const index = timestampOptions[0].findIndex((item) => item.timestamp === selectedTimestamps[0]);
								if (index < timestampOptions[0].length - 1) {
									setSelectedTimestamps((prev) => {
										const newTimestamps = [...prev];
										newTimestamps[0] = timestampOptions[0][index + 1].timestamp;
										return newTimestamps;
									});
									onChangeAddedForcastTimeStamp(timestampOptions[0][index + 1].timestamp, 0);
								}
							}}
						/>
					</div>
				</div>

				{addedForecasts.map((forecast, index) =>
					<div key={index}>
						<p>Forecast {index + 1}</p>
						<SelectInput
							id={`forecast-server-${index}`}
							label={"Server"}
							options={serverStore.user_servers.filter((server) => localStorageKeys.includes(server.domain_name))}
							fields={{ label: "domain_name", value: "domain_name" }}
							value={forecast.server}
							onChange={(e) => {
								setAddedForecasts((prev) => {
									const newForecasts = [...prev];
									newForecasts[index].server = e.target.value;
									newForecasts[index].station = "";
									newForecasts[index].dataSeries = "";
									newForecasts[index].dataSeriesOptions = [];
									return newForecasts;
								}
								);
							}}
						/>
						<SelectInput
							id={`forecast-station-${index}`}
							label={"Station"}
							options={stationStore.items.slice().find((item) => item.server === forecast.server)?.stations?.slice()}
							fields={{ label: "name", value: "id" }}
							value={forecast.station}
							onChange={(e) => {
								setIsOptionLoading((prev) => ({ ...prev, dataSeriesNew: true }));
								setAddedForecasts((prev) => {
									const newForecasts = [...prev];
									newForecasts[index].station = e.target.value;
									newForecasts[index].dataSeries = "";
									newForecasts[index].dataSeriesOptions = [];
									return newForecasts;
								});
								const token = getFromLocalStorage(forecast.server);
								fetch(`${forecast.server}/api/station/${+e.target.value}/data-series`, {
									method: "GET",
									headers: {
										Authorization: `Bearer ${token}`
									}
								})
									.then((res) => res.json())
									.then((data) => {
										setAddedForecasts((prev) => {
											const newForecasts = [...prev];
											newForecasts[index].dataSeriesOptions = data;
											return newForecasts;
										});
										setIsOptionLoading((prev) => ({ ...prev, dataSeriesNew: false }));
									})
									.catch((e) => {
										console.error(e);
									});
							}}
						/>
						<SelectInput
							id={`forecast-dataSeries-${index}`}
							label={"Data Series"}
							options={forecast.dataSeriesOptions.length > 0 ? forecast.dataSeriesOptions : []}
							fields={{ label: "name", value: "id" }}
							value={forecast.dataSeries}
							isLoading={isOptionLoading.dataSeriesNew}
							onChange={(e) => {
								setAddedForecasts((prev) => {
									const newForecasts = [...prev];
									newForecasts[index].dataSeries = e.target.value;
									return newForecasts;
								});
							}}
						/>
						<SelectInput
							id={`forecast-timestamp-${index}`}
							label={"Timestamp"}
							options={timestampOptions.length > 0 ? timestampOptions[index + 1] : []}
							fields={{ label: "timestamp", value: "timestamp" }}
							value={selectedTimestamps[index + 1]}
							onChange={(e) => {
								setSelectedTimestamps((prev) => {
									const newSelectedTimestamps = [...prev];
									newSelectedTimestamps[index + 1] = e.target.value;
									return newSelectedTimestamps;
								});
								onChangeAddedForcastTimeStamp(e.target.value, index + 1);
							}}
						/>
						<div
							className="flex"
							style={{ justifyContent: "center" }}
						>
							<i
								className="fa fa-caret-left hovericon"
								style={{ fontSize: "32px" }}
								onClick={(e) => {
									const timeIndex = timestampOptions[index + 1].findIndex((item) => item.timestamp === selectedTimestamps[index + 1]);
									if (timeIndex > 0) {
										setSelectedTimestamps((prev) => {
											const newSelectedTimestamps = [...prev];
											newSelectedTimestamps[index + 1] = timestampOptions[index + 1][timeIndex - 1].timestamp;
											return newSelectedTimestamps;
										});
										onChangeAddedForcastTimeStamp(timestampOptions[index + 1][timeIndex - 1].timestamp, index + 1);
									}
								}}
							/>

							<i
								className="fa fa-solid fa-caret-right hovericon"
								style={{ paddingLeft: "20px", fontSize: "32px" }}
								onClick={(e) => {
									const timeIndex = timestampOptions[index + 1].findIndex((item) => item.timestamp === selectedTimestamps[index + 1]);
									if (timeIndex < timestampOptions[index + 1].length - 1) {
										setSelectedTimestamps((prev) => {
											const newSelectedTimestamps = [...prev];
											newSelectedTimestamps[index + 1] = timestampOptions[index + 1][timeIndex + 1].timestamp;
											return newSelectedTimestamps;
										});
										onChangeAddedForcastTimeStamp(timestampOptions[index + 1][timeIndex + 1].timestamp, index + 1);
									}
								}}
							/>
						</div>
						<button onClick={(e) => {
							setAddedForecasts((prev) => {
								const newForecasts = [...prev];
								newForecasts.splice(index, 1);
								return newForecasts;
							});
							setTimestampOptions((prev) => {
								const newTimestampOptions = [...prev];
								newTimestampOptions.splice(index + 1, 1);
								return newTimestampOptions;
							});
							setSelectedTimestamps((prev) => {
								const newSelectedTimestamps = [...prev];
								newSelectedTimestamps.splice(index + 1, 1);
								return newSelectedTimestamps;
							});
							setDerivedValues((prev) => {
								const newDerivedValues = [...prev];
								newDerivedValues.splice(index + 1, 1);
								return newDerivedValues;
							});
						}}>
							Remove
						</button>
					</div>
				)}

				<div>
					<p>Derived (Observation and Forecast)</p>

					<div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
						<div
							style={{
								marginLeft: '10px',
								fontWeight: 'bold'
							}}
						>
							<p>RMSE: </p>
							<p>Correlation Coefficient:</p>
							<p>Mean Bias Error:</p>
							<p>Mean Absoulute % Error: </p>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-around' }}>
							{derivedValues.map((derivedValue, i) =>
								<div style={{ marginLeft: '30px' }} key={i}>
									<p>{derivedValue.RMSE ? derivedValue.RMSE.toFixed(2) : '-'}</p>
									<p>{derivedValue.correlationCoefficient ? derivedValue.correlationCoefficient.toFixed(2) : '-'}</p>
									<p>{derivedValue.meanBiasError ? derivedValue.meanBiasError.toFixed(2) : '-'}</p>
									<p>{derivedValue.meanAbsoulutePercentageError ? `${derivedValue.meanAbsoulutePercentageError.toFixed(2)} %` : '-'}</p>
								</div>
							)}
						</div>
					</div>
					<button onClick={() => setShowForecastAdd(true)}>Add more forecast</button>
					<SelectInput
						label={"Forecast Bias"}
						options={[
							{ label: "Forecast", value: 0 },
							...addedForecasts.map((_, index) => {
								return { label: `Forecast ${index + 1}`, value: index + 1 };
							})
						]}
						fields={{ label: "label", value: "value" }}
						value={forecastBiasOption}
						onChange={(e) => {
							setForecastBiasOption(e.target.value);
						}}
					/>

				</div>
			</div>

			<Graph graphData={graphData.filter((data) => {
				if (data.forecastIndex === undefined) return true;
				return data.forecastIndex === forecastBiasOption;
			})} />
		</div>
	);
});
