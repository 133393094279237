import { BASE_URL } from "../config";
import { token } from "../localStorage";
import { toast } from "react-toastify";
import { getFromLocalStorage } from "../localStorage";

export const callApi = async (method, endpoint, body) => {
	try {
		const fullUrl = `${BASE_URL}/${endpoint}`;
		const options = {
			method,
			headers: {
				Accept: "application/json",
				"Content-type": "application/json",
				Authorization: `Bearer ${token.get()}`
			}
		};
		if (body) Object.assign(options, { body: JSON.stringify(body) });
		const response = await fetch(fullUrl, options);
		const data = await response.json();
		if (!response.ok) {
			throw new Error(data.message);
		}
		return data;
	} catch (e) {
		toast.error(e.message || "Error connecting to server");
		return;
	}
};

export const get = (endpoint) => callApi("GET", endpoint);
export const post = (endpoint, body) => callApi("POST", endpoint, body);
export const put = (endpoint, body) => callApi("PUT", endpoint, body);
export const del = (endpoint, body) => callApi("DELETE", endpoint, body);

export const getAll = (endpoint) => {
	const allTokens = getFromLocalStorage("all_tokens");
	return callApi("POST", endpoint, { tokens: JSON.parse(allTokens) });
}
