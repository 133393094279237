import { observer } from "mobx-react-lite";

export const DateInput = observer(({ label, value = "", readOnly, onChange, type, name }) => (
    <div className="formInput">
        <label>{label}</label>
        <input
            name={name || ""}
            type={type ? type : "date"}
            value={value || ""}
            readOnly={readOnly}
            onChange={onChange}
        />
    </div>
));