import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../Common/DataTable/DataTable";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { callApi } from "../../actions/apiMethods";

export const ServerList = observer(() => {
	const localStorageKeys = Object.keys(localStorage);

	const rootStore = useContext(RootContext);
	const serverStore = rootStore.serverStore;

	const [showAddServer, setShowAddServer] = useState(false);
	const [showAddUserServer, setShowAddUserServer] = useState(false);

	const [loggedInDomains, setLoggedInDomains] = useState(localStorageKeys);

	const navigate = useNavigate();

	useEffect(() => {
		if (!serverStore.servers.length) serverStore.fetchServers();
		if (!serverStore.user_servers.length) serverStore.fetchUserServers();
	}, []);

	const [serverInfo, setServerInfo] = useState({
		caption: "",
		description: "",
		domain_name: ""
	});

	const [userServerInfo, setUserServerInfo] = useState({
		server: "",
		server_username: "",
		server_password: ""
	});

	const setNewServerInfo = (e) => {
		const { name, value } = e.target;
		setUserServerInfo((prev) => ({ ...prev, [name]: value }));
	};

	const setNewForm = (e) => {
		const { name, value } = e.target;
		setServerInfo((prev) => ({ ...prev, [name]: value }));
	};

	const addUserServer = async (e) => {
		e.preventDefault();
		if (!userServerInfo.server || !userServerInfo.server_username || !userServerInfo.server_password) {
			toast.error("Missing fields");
			return;
		}
		const result = await serverStore.addUserServer(userServerInfo);
		if (result) setShowAddUserServer(false);
	};

	const deleteUserServer = async (id) => {
		if (!window.confirm("Delete credential?")) return;
		serverStore.deleteUserServer(id);
	};

	const addServer = async (e) => {
		e.preventDefault();
		if (!serverInfo.caption || !serverInfo.domain_name || !serverInfo.description) {
			toast.error("Missing fields");
			return;
		}
		serverStore.addServer(serverInfo);
	};

	const tryLogin = async (server) => {
		const response = await callApi("POST", `re-login`, { server_id: server.server_id, domain_name: server.domain_name });
		if (!response) return;
		if (response.token) {
			localStorage.setItem(server.domain_name, response.token);
			setLoggedInDomains((prev) => [...prev, server.domain_name]);
			toast.success("Logged in");
		}
	}

	return (
		<div>
			<DataTable
				title="Servers"
				data={serverStore.servers}
				headers={[{ label: "Server", key: "domain_name" }, { label: "Caption", key: "caption" }, { label: "Description", key: "description" }]}
				handleAdd={() => setShowAddServer(true)}
				handleEdit={(id) => {
					navigate(`/server/${id}`);
				}}
			/>
			<br />
			<DataTable
				title="Your servers"
				data={serverStore.user_servers.map((server) => {
					return {
						...server,
						logged_in: loggedInDomains.includes(server.domain_name) ? "Yes" : "No",
						button: loggedInDomains.includes(server.domain_name) ? "" : <button onClick={() => tryLogin(server)} className="button">Try again</button>
					};
				})}
				headers={[{ label: "Server", key: "domain_name" }, { label: "Username", key: "server_username" }, { label: "Logged in", key: "logged_in" }, { label: "", key: "button" }]}
				handleAdd={() => setShowAddUserServer(true)}
				handleEdit={(id) => {
					navigate(`/user-server/${id}`);
				}}
				handleDelete={(id) => deleteUserServer(id)}
			/>

			{showAddServer && (
				<Modal
					title={"Add server"}
					closeModal={() => {
						setShowAddServer(false);
					}}
				>
					<form className="Form">
						<label>Caption</label>
						<input
							type="text"
							name="caption"
							value={serverInfo.caption}
							onChange={setNewForm}
						/>

						<label>Domain name</label>
						<input
							type="text"
							name="domain_name"
							value={serverInfo.domain_name}
							onChange={setNewForm}
						/>

						<label>Description</label>
						<input
							type="text"
							name="description"
							value={serverInfo.description}
							onChange={setNewForm}
						/>
						<br />
						<button onClick={(e) => addServer(e)}>Submit</button>
					</form>
				</Modal>
			)}

			{showAddUserServer && (
				<Modal
					title={"Add your server"}
					closeModal={() => {
						setShowAddUserServer(false);
					}}
				>
					<form
						className="Form"
						autoComplete="off"
					>
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id="demo-simple-select-label">Server</InputLabel>
							<Select
								name="server"
								value={userServerInfo.server}
								label="Server One"
								onChange={setNewServerInfo}
							>
								{serverStore.servers.map((server) => (
									<MenuItem value={server.id}>{server.domain_name}</MenuItem>
								))}
							</Select>
						</FormControl>

						<label>Server Username</label>
						<input
							type="text"
							name="server_username"
							value={userServerInfo.server_username}
							autoComplete="off"
							onChange={setNewServerInfo}
						/>

						<label>Server Password</label>
						<input
							type="text"
							name="server_password"
							value={userServerInfo.server_password}
							autoComplete="off"
							onChange={setNewServerInfo}
						/>

						<br />
						<button onClick={(e) => addUserServer(e)}>Submit</button>
					</form>
				</Modal>
			)}

			<Outlet />
		</div>
	);
});
