import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { callApi } from "../actions/apiMethods";
import moment from "moment";
import { DateInput } from "./Common/Form";
import { getFromLocalStorage } from "../localStorage";
import { Graph } from "./Common/Graph";
import _ from "lodash";
import SelectInput from "./Common/SelectInput";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { calculateCorrelationCoefficient, calculateMeanAbsolutePercentageError, calculateMeanBiasError } from "../helpers/calculations";

export const GeoServerComparision = observer(() => {
  const [server, setServer] = useState("");
  const [yourservers, setYourServers] = useState([]);
  const [stationOne, setStationOne] = useState("");
  const [stationOneOptions, setStationOneOptions] = useState([]);
  const [dataSeriesOne, setDataSeriesOne] = useState("");
  const [dataSeriesOneOptions, setDataSeriesOneOptions] = useState([]);

  const [filters, setFilters] = useState({
    date_from: moment().utc().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
    date_to: moment().utc().format("YYYY-MM-DDTHH:mm:ss")
  });

  const [RMSE, setRMSE] = useState(0);
  const [correlationCoefficient, setCorrelationCoefficient] = useState(0);
  const [meanBiasError, setMeanBiasError] = useState(0);
  const [meanAbsoulutePercentageError, setMeanAbsoulutePercentageError] = useState(0);

  const [graphData, setGraphData] = useState({
    name1: "",
    name2: "",
    name3: "Forecast Bias",
    data1: [],
    data2: [],
    data3: [],
    symbol1: "",
    symbol2: "",
    symbol3: "bias"
  });

  const [isOptionLoading, setIsOptionLoading] = useState({
    stationOne: false,
    stationTwo: false,
    dataSeriesOne: false,
    dataSeriesTwo: false
  })


  const [selectedGPMOption, setSelectedGPMOption] = useState("");

  const [loading, setLoading] = useState(false);

  const setNewFilters = (filter, value) => setFilters((prev) => ({ ...prev, [filter]: value }));

  useEffect(() => {
    async function getData() {
      const yourserverresponse = await callApi("GET", "user-server");
      if (!yourserverresponse) return;
      setYourServers(yourserverresponse.message);
    }
    getData();
  }, []);

  const checkDateValidForGeoServer = (date_from, date_to, geoserver) => {
    if (geoserver === "era5") {
      if (moment(date_to).isAfter('2022-12-31')) {
        toast.error("Date should be upto 2022 for ERA5");
        return false;
      }
    }
    if (geoserver === "gpm_late") {
      if (moment(date_to).isAfter('2022-12-31')) {
        toast.error("Date should be upto 2022 for GPM Late");
        return false;
      }
    }
    if (geoserver === "gpm_final") {
      if (moment(date_to).isAfter('2021-12-31')) {
        toast.error("Date should be upto 2021 for GPM Final");
        return false;
      }
    }
    if (geoserver === "gpm_test") {
      if (moment(date_from).isBefore('2023-04-01') || moment(date_to).isAfter('2023-07-01')) {
        toast.error("Date should be between 2023-04-01 and 2023-07-01 for GPM Test");
        return false;
      }
    }
    return true;
  }

  const renderGraph = async () => {
    setLoading(true);
    try {
      const token1 = getFromLocalStorage(server);
      if (!server || !dataSeriesOne || !stationOne || !filters.date_from || !filters.date_to || !selectedGPMOption) {
        toast.error("Select all options");
        setLoading(false);
        return;
      }
      if (moment(filters.date_from).isAfter(filters.date_to)) {
        toast.error("Date From should be less than Date To");
        setLoading(false);
        return;
      }

      if (!checkDateValidForGeoServer(filters.date_from, filters.date_to, selectedGPMOption)) {
        setLoading(false);
        return;
      }

      const res1 = await fetch(
        `${server}/api/observation/index?series_id=${dataSeriesOne}&date_from=${moment.utc(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}&date_to=${moment
          .utc(filters.date_to)
          .format("YYYY-MM-DDTHH:mm:ss")}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token1}`
          }
        }
      );
      const data1 = await res1.json();

      let groupedObservation = _.groupBy(data1, (item) => item.time);

      let uniqueObservations = [];
      Object.keys(groupedObservation).forEach((datekey) => {
        const maxTimestampObject = groupedObservation[datekey].reduce((maxObj, currentObj) => {
          return new Date(currentObj.timestamp) > new Date(maxObj.timestamp) ? currentObj : maxObj;
        });
        uniqueObservations.push(maxTimestampObject);
      });

      const seriesData1 =
        uniqueObservations.map(({ time, value }) => {
          return [moment.utc(time).valueOf(), +value];
        }) || [];

      const name1 = `${stationOneOptions.find((station) => +station.id === +stationOne).name} (${data1[0] ? data1[0].name : ""})`;
      const symbol1 = data1[0] ? data1[0].symbol : "";

      const stationOneData = await fetch(`${server}/api/station/${stationOne}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token1}`
        }
      });

      const stationOneDataJson = await stationOneData.json(); // .latitude, longitude

      const geoServerData = await fetch(`https://agg.wscada.net/dataapi/${selectedGPMOption}?latitude=${stationOneDataJson.latitude}&longitude=${stationOneDataJson.longitude}&start_time=${moment
        .utc(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}&end_time=${moment.utc(filters.date_to).format("YYYY-MM-DDTHH:mm:ss")}`, {
        method: "GET"
      });
      let geoServerDataJson = await geoServerData.json();

      if (selectedGPMOption === "gpmobservation" || selectedGPMOption === "gpm_test") {
        //change data into hourly data
        const hourlyData = [];
        const groupedData = _.groupBy(geoServerDataJson, (item) => moment(item.datetime).format("YYYY-MM-DDTHH"));
        Object.keys(groupedData).forEach((datekey) => {
          const sumOfValues = groupedData[datekey].reduce((acc, item) => acc + Number(item.value), 0);
          let dt = moment(datekey).add(1, 'hour').format("YYYY-MM-DDTHH:mm:ss");
          hourlyData.push({ datetime: dt, value: sumOfValues });
        });
        geoServerDataJson = hourlyData;
      }
      const seriesData2 = geoServerDataJson.map((data) => {
        return [moment.utc(data.datetime).valueOf(), Number(data.value)];
      });

      const name2 = `${selectedGPMOption}`;
      const symbol2 = "" //" geoserver";

      //rootMeanSquareError, correlation, meanBias and meanAbsoulutePercentageError
      const timestampsData1 = new Set(seriesData1.map((item) => item[0]));
      const timestampsData2 = new Set(seriesData2.map((item) => item[0]));

      const commonTimestamps = new Set([...timestampsData1].filter((timestamp) => timestampsData2.has(timestamp)));
      const filteredData1 = [];
      const filteredData2 = [];

      if (commonTimestamps.size === 0) {
        toast.warning("No common dates found");
      }

      const resultArray = []
      for (const timestamp of commonTimestamps) {
        const valueData1 = seriesData1 && seriesData1.find((item) => item[0] === timestamp)[1];
        const valueData2 = seriesData2 && seriesData2.find((item) => item[0] === timestamp)[1];
        filteredData1.push(valueData1);
        filteredData2.push(valueData2);
        const difference = valueData2 - valueData1;
        resultArray.push([timestamp, difference]);
      }

      const squaredErrors = resultArray.map(([_, difference]) => difference ** 2);
      const meanSquaredError = squaredErrors.reduce((acc, error) => acc + error, 0) / squaredErrors.length;
      const rootMeanSquareError = Math.sqrt(meanSquaredError);

      setRMSE(rootMeanSquareError);
      setCorrelationCoefficient(calculateCorrelationCoefficient(filteredData1, filteredData2));
      setMeanBiasError(calculateMeanBiasError(filteredData1, filteredData2));
      setMeanAbsoulutePercentageError(calculateMeanAbsolutePercentageError(filteredData1, filteredData2));

      setGraphData({
        name1: name1,
        name2: name2,
        name3: "Forecast Bias",
        data1: seriesData1,
        data2: seriesData2,
        data3: resultArray,
        symbol1: symbol1,
        symbol2: symbol2,
        symbol3: "bias",
      });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error("Error fetching data");
    }
  };


  const localStorageKeys = Object.keys(localStorage);

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>GeoServer Comparision</h1>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div>
          <p>TSS</p>
          <SelectInput
            label={"Server"}
            options={yourservers.filter((server) => localStorageKeys.includes(server.domain_name))}
            fields={{ label: "domain_name", value: "domain_name" }}
            value={server}
            onChange={(e) => {
              setIsOptionLoading((prev) => ({ ...prev, stationOne: true }));
              setServer(e.target.value);
              const token = getFromLocalStorage(e.target.value);
              fetch(`${e.target.value}/api/station`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })
                .then((res) => res.json())
                .then((data) => {
                  setStationOne("");
                  setDataSeriesOne("");
                  setStationOneOptions(data);
                  setIsOptionLoading((prev) => ({ ...prev, stationOne: false }));
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          />

          <SelectInput
            label={"Station"}
            options={stationOneOptions.length > 0 ? stationOneOptions : []}
            fields={{ label: "name", value: "id" }}
            value={stationOne}
            isLoading={isOptionLoading.stationOne}
            onChange={(e) => {
              setIsOptionLoading((prev) => ({ ...prev, dataSeriesOne: true }));
              setStationOne(e.target.value);
              const token = getFromLocalStorage(server);
              fetch(`${server}/api/station/${+e.target.value}/data-series`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })
                .then((res) => res.json())
                .then((data) => {
                  setDataSeriesOne("");
                  setDataSeriesOneOptions(data);
                  setIsOptionLoading((prev) => ({ ...prev, dataSeriesOne: false }));
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          />

          <SelectInput
            label={"Data Series"}
            options={dataSeriesOneOptions.length > 0 ? dataSeriesOneOptions : []}
            fields={{ label: "name", value: "id" }}
            value={dataSeriesOne}
            isLoading={isOptionLoading.dataSeriesOne}
            onChange={(e) => {
              setDataSeriesOne(e.target.value);
            }}
          />

          <DateInput
            label={"Date From"}
            type="datetime-local"
            value={moment(filters.date_from).format("YYYY-MM-DDTHH:mm:ss")}
            onChange={(e) => setNewFilters("date_from", e.target.value)}
          />
          <DateInput
            label={"Date To"}
            type="datetime-local"
            value={moment(filters.date_to).format("YYYY-MM-DDTHH:mm:ss")}
            onChange={(e) => setNewFilters("date_to", e.target.value)}
          />

          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => renderGraph()}
          >
            View
          </LoadingButton>
        </div>

        <div>
          <SelectInput
            label={"GeoServer"}
            options={[
              { label: "Era5", value: "era5" },
              { label: "GPM Final", value: "gpm_final" },
              { label: "GPM Late", value: "gpm_late" },
              { label: "GPM Observation", value: "gpmobservation" },
              { label: "GPM Test", value: "gpm_test" }
            ]}
            fields={{ label: "label", value: "value" }}
            value={selectedGPMOption}
            onChange={(e) => setSelectedGPMOption(e.target.value)}
          />
        </div>

        <div>
          <p>Derived</p>
          <p style={{ fontSize: "12px" }}>
            <b> RMSE:</b> {RMSE ? RMSE.toFixed(2) : "-  "}
          </p>
          <p style={{ fontSize: "12px" }}>
            <b> Correlation Coefficient:</b> {correlationCoefficient ? correlationCoefficient.toFixed(2) : "-  "}
          </p>
          <p style={{ fontSize: "12px" }}>
            <b> Mean Bias Error:</b> {meanBiasError ? meanBiasError.toFixed(2) : "-  "}
          </p>
          <p style={{ fontSize: "12px" }}>
            <b> Mean Absoulute Percentage Error:</b> {meanAbsoulutePercentageError ? `${meanAbsoulutePercentageError.toFixed(2)} %` : "-  "}
          </p>
        </div>
      </div>

      <Graph graphData={graphData} />
    </div>
  );
});
