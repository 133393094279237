import React from "react";
import "./DataTable.css";

export const DataTable = ({ headers, data, handleAdd, handleEdit, handleDelete, title }) => {
	return (
		<div className="data-table-container">
			{title && <h1>{title}</h1>}
			<table className="data-table">
				<thead>
					<tr>
						{headers.map((header, index) => (
							<th key={index}>{header.label}</th>
						))}
						<th>
							{handleAdd && (
								<span className="actions">
									<i
										className="fas fa-plus plus-icon"
										onClick={handleAdd}
									></i>
								</span>
							)}
						</th>
					</tr>
				</thead>

				<tbody>
					{data &&
						data.map((data, index) => (
							<tr key={index}>
								{headers.map((header, i) => (
									<td
										style={{ padding: "5px" }}
										key={i}
									>
										{data[header.key]}
									</td>
								))}
								<td className="action-icons">
									{handleEdit && (
										<i
											className="fa fa-pencil"
											style={{ cursor: "pointer" }}
											onClick={() => {
												handleEdit(data.id, data);
											}}
										/>
									)}
									{handleDelete && (
										<i
											className="fa fa-trash"
											style={{ cursor: "pointer", paddingLeft: "5px" }}
											onClick={() => {
												handleDelete(data.id);
											}}
										/>
									)}
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};
