import ClipLoader from "react-spinners/ClipLoader";

export const Spinner = ({ small }) => {
	return (
		<ClipLoader
			color="#3C8DBC"
			size={small ? 30 : 100}
			cssOverride={{ display: "block", margin: "auto" }}
		/>
	);
};
