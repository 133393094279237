import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class serverStore {
	servers = [];
	user_servers = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}

	fetchServers = async () => {
		this.loading = true;
		const response = await callApi("GET", "server");
		if (!response) return;
		runInAction(() => {
			this.loading = false;
			this.servers = response.message;
		});
	};

	addServer = async (formEntries) => {
		const response = await callApi("POST", "server", formEntries);
		if (!response) return;
		runInAction(() => {
			this.servers.push(formEntries);
		});
		toast.success(response.message);
		return response.message;
	};

	editServer = async (id, serverInfo) => {
		const response = await callApi("PUT", `server/${id}`, serverInfo);
		if (!response) return;
		runInAction(() => {
			this.servers = this.servers.map((server) => (server.id === id ? { ...server, ...serverInfo } : server));
		});
		toast.success(response.message);
		return response.message;
	};

	fetchUserServers = async () => {
		this.loading = true;
		const response = await callApi("GET", "user-server");
		if (!response) return;
		runInAction(() => {
			this.loading = false;
			this.user_servers = response.message;
		});
	};

	addUserServer = async (serverInfo) => {
		const response = await callApi("POST", "user-server", serverInfo);
		if (!response) return;
		runInAction(() => {
			this.user_servers.push({
				server_username: serverInfo.server_username,
				id: serverInfo.server,
				domain_name: this.servers.find((server) => +server.id === +serverInfo.server).domain_name
			});
		});
		toast.success(response.message);
		return response.message;
	};

	deleteUserServer = async (id) => {
		const response = await callApi("DELETE", `user-server/${id}`);
		if (!response) return;
		runInAction(() => {
			this.user_servers = this.user_servers.filter((server) => +server.id !== +id);
		});
		toast.success(response.message);
	};

	editUserServer = async (id, serverInfo) => {
		const response = await callApi("PUT", `user-server/${id}`, serverInfo);
		if (!response) return;
		runInAction(() => {
			this.user_servers = this.user_servers.map((server) => (server.id === id ? { ...server, ...serverInfo } : server));
		});
		toast.success(response.message);
		return response.message;
	};
}
